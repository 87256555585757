import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import Widget from '../../../components/Widget';
import { Multiselect } from 'multiselect-react-dropdown';
import { withRouter } from "react-router-dom";
import Api from '../../../components/RestApis/Api';
// import firebase from "firebase";
// const db = firebase.firestore();

class CouponNew extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor(props) {
    super(props);

    this.state = {
      code: '',
      datefrom: '',
      dateto: '',
      discount:'',
      maxUsers: '',
      restlist:[],
      errormsg:""}
  }
componentDidMount=async()=>
{
  try{
    let response= await Api.restaurantsList();
    if(response && response.data.status === "Success")
    console.log("response : "+response)
    {
      const filter=[];
      response.data.Vendors.forEach(doc => {
        filter.push({name:doc.name,id:doc._id});
      });
      this.setState({restlist:filter});
    }
  }
  catch(e)
  {
    this.setState({errormsg:e.message});
  }
}

changecode = (event) => {
  this.setState({code: event.target.value});
}
changedatefrom = (event) => {
  this.setState({datefrom: event.target.value});
}
changedateto = (event) => {
  this.setState({dateto: event.target.value});
}

changeDiscount = (event) =>{
  this.setState({discount: event.target.value});
}

changeMaxUsers = (event)=>{
  this.setState({maxUsers: event.target.value});
}

datecompare(){
  const g1 = new Date(this.state.datefrom);
  const g2 = new Date(this.state.dateto);
  if (g1.getTime() < g2.getTime())
  {
    return true;
  }
  else
  {
    return "Starting Date Must be Less than Enddate";
  }
}

  doCreatePost = async(e) => {
    e.preventDefault();
    let dateco=this.datecompare();
    
    if(dateco === true){
      let formData = new FormData();

      formData.append("code",this.state.code);
      formData.append("datefrom",this.state.datefrom);
      formData.append("dateto",this.state.dateto);
      formData.append("discount",this.state.discount);
      formData.append("maxUsers",this.state.maxUsers);
      formData.append("vendor_id",this.state.selectedCat[0].id);

    try{
      let response= await Api.addCouponByAdmin(formData);
      if(response.data && response.data.status === "success")
      {
        alert(response.data.message);
        this.props.history.push('/app/coupons');
      }
      else
      {
        alert(response.error.response.data.message);
      }
    }
    catch(e)
    {
      alert(e.message);
    }
  }
}


  onSelectCat(selectedList, selectedItem) {
    this.setState({selectedCat:selectedList});
}
// onRemoveCat(selectedList, removedItem) {
//   this.setState({selectedCat:selectedList});
// }


  render() {
    const {code,datefrom,dateto,discount,maxUsers} = this.state;
    return (
      <div>
        <h1>Create new Coupon</h1>
        <Row>
          <Col sm={6}>
            <Widget
              title={
                <span>
                  Add Coupon
                </span>
              }
            >
              <Form onSubmit={this.doCreatePost}>
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="info">
                    {this.props.message}
                  </Alert>
                )}<FormGroup>
                <Label for="input-title">Select Reastaurants</Label>
                <Multiselect
options={this.state.restlist} // Options to display in the dropdown
selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
onSelect={this.onSelectCat.bind(this)} // Function will trigger on select event
//onRemove={this.onRemoveCat.bind(this)} // Function will trigger on remove event
displayValue="name" // Property name to display in the dropdown options
/>
        </FormGroup>
        <FormGroup>
                  <Label for="input-code">Code</Label>
                  <Input
                    id="input-code"
                    type="text"
                    placeholder="Code"
                    value={code}
                    required
                    onChange={this.changecode}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-datefrom">Start Date</Label>
                  <Input
                    id="input-datefrom"
                    type="date"
                    placeholder="Date From"
                    value={datefrom}
                    onChange={this.changedatefrom}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-dateto">End Date</Label>
                  <Input
                    id="input-dateto"
                    type="date"
                    placeholder="Date To"
                    value={dateto}
                    onChange={this.changedateto}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-code">Discount</Label>
                  <Input
                    id="input-code"
                    type="number"
                    placeholder="%"
                    value={discount}
                    required
                    onChange={this.changeDiscount}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-code">Usage limit</Label>
                  <Input
                    id="input-code"
                    type="number"
                    placeholder="Enter 10 means 10 users can use it!"
                    value={maxUsers}
                    required
                    onChange={this.changeMaxUsers}
                  />
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="danger" type="submit">
                      {this.props.isFetching ? 'Creating...' : 'Create'}
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}



export default withRouter(CouponNew);
