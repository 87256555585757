import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

import { Link } from 'react-router-dom';
// components
import PageTitle from "../../../components/PageTitle";
import $ from 'jquery';
// data
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
import Api from '../../../components/RestApis/Api.js';

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";




class OrderList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor() {
    super();
    this.state = { dataSet: [], dataSet2: [] }
  }

  dateConverter = (d) => {
    let dF = new Date(d);
    let month = '' + (dF.getMonth() + 1)
    let day = '' + dF.getDate()
    let year = dF.getFullYear()
    return [day, month, year].join('-');
  }

  componentDidMount = async () => {
    try {
      let response = await Api.ordersList();
      if (response !== "" || response.status == "Success") {
        const filter = [];
        response.data.Orders.forEach(item => {
          filter.push([item._id, item.Name, item.Email, item.Phone, item.Address, this.dateConverter(item.Dates), item.Total, item.Earning, item.PaymentStatus, item.Method, item._id])
        });
        this.setState({ dataSet: filter });
      }
    }
    catch (e) {
      alert(e.message)
    }
  }

  // componentDidMount = async() => {
  //   try {
  //      let response = await axios.get('https://group-buy.thermalcode.com/appapi/admin_side_orders');
  //      let mainData = await response.data;
  //      if(response !== "" || response.status == "Success") {
  //       const filter = [];
  //       mainData.Orders.forEach((item) => {
  //         filter.push([ item._id, item.Name, item.Email, item.Phone, item.Address, item.Dates, item.Total, item.Earning, item.PaymentStatus, item.Method])
  //       });
  //       this.setState({ dataSet : filter })
  //      }
  //   }
  //   catch (e) {
  //     alert(e.message);
  //   }
  // }



  openpop(e, id) {
    $('#pop').removeClass('blockend');
    console.log("After click on vue button", e.target);
  }


  closepop() {
    $('#pop').addClass('blockend');
    this.setState({ dataSet2: [] })
  }

  showData(e, id) {
    console.log(id);
  }

  options = {
    filterType: "checkbox",
    rowsPerPage: [3],
    rowsPerPageOptions: [1, 3, 5, 6],
    jumpToPage: true,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total items Per Page",
        displayRows: "OF"
      }
    },
    onChangePage(currentPage) {
      console.log({ currentPage });
    },
    onChangeRowsPerPage(numberOfRows) {
      console.log({ numberOfRows });
    }
  };

  getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTable: {
          root: {
            // backgroundColor: "#FF000"
          },
          paper: {
            boxShadow: "none"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            fontSize: '1.4rem'
          }
        },

        MuiTablePagination: {
          root: {
            fontSize: '1.5rem',
            fontWeight: '600'
          },

          caption: {
            fontSize: '1.5rem',
            fontWeight: 'Bold'
          },

          actions: {
            fontSize: '1.5rem'
          }

        },

        Muititle: {
          fontSize: '2rem'
        },

        MuiTypography: {
          root: {
            fontSize: '1.4rem',
            fontWeight: '700',
            lineHeight: '1.43',
            letterSpacing: '0.01071em'
          }
        },

        MUIDataTableHeadCell: {
          toolButton: {
            fontSize: '1.4rem',
            fontWeight: 'Bold'
          }
        },

        MuiSvgIcon: {
          root: {
            fontSize: '3.4rem'
          }
        },

        MuiSelect: {
          select: {
            paddingTop: '13px',
            paddingRight: '30px'
          }
        },

        MuiInputBase:{
          input: {
            fontSize: '20px',
            border: '0',
            height: '6px',
            marginTop: '20px',
            display: 'block',
            padding: '12px',
            minWidth: '0',
            background: 'none',
            boxSizing: 'content-box',
            animationName: 'mui-auto-fill-cancel',
            letterSpacing: 'inherit',
            animationDuration: '10ms',
            webkitTapHighlightColor: 'transparent'
            }
          },

       
      },
    });

 

  render() {
    return (
      <>
        <div class="row">
          <div class="col-md-8"><PageTitle title="Orders" /></div>
          <div class="col-md-4"> </div>
        </div>

        <Grid container spacing={4}>
          <Grid item xs={12}>

            <MuiThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable
                title="Orders List"
                data={this.state.dataSet}
                columns={["Order Id", "Name", "Email", "Phone", "Address", "Dates", "Total", "Earning", "Payment Status", "Method", {
                  label: "See Dishes",
                  options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        // <Link onClick={this.openpop.bind(this, value)} className='btn btn-primary'> View </Link>
                        // <Link onClick={() => this.openpop(value)} className='btn btn-primary'> View </Link>
                        <Link to={"/app/orders/seeDishes/" + value} className='btn btn-primary'> View </Link>
                      )
                    },
                  }
                }
                ]}
                options={{
                  filterType: "checkbox",
                  selectableRows: false
                }}
              />
            </MuiThemeProvider>

          </Grid>

        </Grid>

        {/* For Dishesh list  */}

        <div id="pop" class="blockend">

          <div class="uupdiv">
            <i class="fa fa-times" aria-hidden="true" onClick={this.closepop.bind(this)}></i>
            <MUIDataTable
              title=""
              data={this.state.dataSet2}
              columns={["Dish Name", "Image", "Actual Price", "Quantity"
              ]}
              options={{
                filterType: "checkbox",
              }}
            />

          </div>
        </div>
      </>
    );
  }
}
export default withRouter(OrderList);




 // data={this.state.dataSet.map((item) => {
              //   return [
              //     item._id, item.Name, item.Email, item.Phone, item.Address, item.Dates, item.Total, item.Earning, item.PaymentStatus, item.Method
              //   ]
              // })}


// data={this.state.dataSet.length > 0 ? this.state.dataSet[0].map((item) => {
                //   return [
                //     item._id, item.Name, item.Email, item.Phone, item.Address, item.Dates, item.Total, item.Earning, item.PaymentStatus, item.Method
                //   ]
                // }) : []}