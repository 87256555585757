import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";

import { Link } from 'react-router-dom';
// components
import PageTitle from "../../../components/PageTitle";
import Widget from "../../../components/Widget";
import Table from "../../dashboard/components/Table/Table";

// data
import mock from "../../dashboard/mock";
import Api from '../../../components/RestApis/Api';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

// const db = firebase.firestore();

class CustomerList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor() {
    super();
    this.state = { dataSet: [] };
  }
  componentDidMount = async () => {
    try {
      let response = await Api.customerList();
      if (response && response.data.status === "success") {
        const filter = [];
        response.data.Customers.forEach(doc => {
          console.log(doc.userName)
          filter.push([doc.userName, doc.email, doc.mobile, doc._id, doc._id]);
        });
        this.setState({ dataSet: filter });
      }
    }
    catch (e) {
      this.setState({ errormsg: e.message });
    }
  }

  customerdelete = async (id) => {
    if (window.confirm("Delete the item?")) {
      try {
        const response = await Api.deleteCustomerById(id);
        console.log('delete!!!!!!!')
        if (response.data && response.data.status === "success") {
          this.componentDidMount();
        }
        else {
          alert(response.error.response.data.message);
        }
      }
      catch (e) {
        alert(e.message);
      }
    }
  }

  getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTable: {
          root: {
            // backgroundColor: "#FF000"
          },
          paper: {
            boxShadow: "none"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            fontSize: '1.4rem'
          }
        },

        MuiTablePagination: {
          root: {
            fontSize: '1.5rem',
            fontWeight: '600'
          },

          caption: {
            fontSize: '1.5rem',
            fontWeight: 'Bold'
          },

          actions: {
            fontSize: '1.5rem'
          }

        },

        Muititle: {
          fontSize: '2rem'
        },

        MuiTypography: {
          root: {
            fontSize: '1.4rem',
            fontWeight: '700',
            lineHeight: '1.43',
            letterSpacing: '0.01071em'
          }
        },

        MUIDataTableHeadCell: {
          toolButton: {
            fontSize: '1.4rem',
            fontWeight: 'Bold'
          }
        },

        MuiSvgIcon: {
          root: {
            fontSize: '3.4rem'
          }
        },

        MuiInputBase:{
          input: {
            fontSize: '20px',
            border: '0',
            height: '6px',
            marginTop: '20px',
            display: 'block',
            padding: '12px',
            minWidth: '0',
            background: 'none',
            boxSizing: 'content-box',
            animationName: 'mui-auto-fill-cancel',
            letterSpacing: 'inherit',
            animationDuration: '10ms',
            webkitTapHighlightColor: 'transparent'
            }
          },

      },
    });

  render() {
    return (
      <>

        <div class="row">
          <div class="col-md-8"><PageTitle title="Customers" /></div>
          <div class="col-md-4"> <Link to="/app/customers/new" className="btn btn-primary btn-inverse pull-right ">
            Create New Customer
          </Link></div>
        </div>

        <Grid container spacing={4}>
          <Grid item xs={12}>

            <MuiThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable
                title=""
                data={this.state.dataSet}
                columns={["Username", "Email", "Phone",{
                  label: "Edit",
                  options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <Link to={"/app/customers/edit/" + value} className='btn btn-primary'> Edit </Link>
                      )
                    }
                  }
                },
                {
                  label: "Delete",
                  options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <button className='btn btn-danger' onClick={() => this.customerdelete(value)}>Delete</button>
                      )
                    }
                  }
                },
  
              
              ]}
                options={{
                  filterType: "checkbox",
                  selectableRows: false
                }}
              />
            </MuiThemeProvider>

          </Grid>
        </Grid>
      </>
    );
  }
}
export default withRouter(CustomerList);


