import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

// import { connect } from 'react-redux';
// import Widget from '../../../components/Widget';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { Editor } from "react-draft-wysiwyg";

import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withRouter } from "react-router-dom";

// import firebase from "firebase";
// import Geocode from "react-geocode";
// import Select from 'react-select';

import Api from '../../../components/RestApis/Api';
import { stringify } from 'uuid';

const apiurl = Api.apiurl; //"http://192.168.1.70:5000/";

const options = [
  { value: 'Own', label: 'Own' },
  { value: 'Goodforlow', label: 'Goodforlow' }
];

class RestaurantEdit extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      vendor_email: '',
      restaurant_owner: '',
      restaurant_url: '',
      address: "",
      aboutus: "",
      openingTiming: "",
      closingTiming: "",
      phone_no: "",
      Latitude: 0,
      Longitude: 0,
      banner: "",
      selectedOption: null,
      commission: "",
      location: [],
      // fileObj: [],
      // files: [],
      dataid: "",
      gmapsLoaded: false,
      error: "",
      editorState: EditorState.createEmpty(),
      description : "",
      formData: new FormData()
    };
  }


  componentDidMount = async () => {
    let id = this.props.match.params.id;
    this.setState({ dataid: id });
    try {
      const response = await Api.restaurantsById(id);
      if (response && response.data.user) {
        let data = response.data.user;

        this.setState({
          name: data.name,
          vendor_email: data.vendor_email,
          banner: Api.image_path + data.banner_image,
          restaurant_url: data.vendor_url,
          status: data.status,
          address: data.address,
          Latitude: data.location.coordinates[0],
          Longitude: data.location.coordinates[1],
          phone_no: data.phone_no,
          openingTiming: data.openingTiming,
          closingTiming: data.closingTiming,
          commission: data.commission ? data.commission : "",
          title: data.title,
          aboutus: data.about_us,
          description : data.description,

          editorState: data.description ? EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(data.description == "undefined" ? "" : data.description))) : ""

        });
      }
    }catch (e) { }
    setTimeout(() => {
      this.initMap();
    }, 2000);
  }

  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    })
  }


  // Input Change Handlers Events 

  changename = (event) => {
    this.setState({ name: event.target.value });
  }

  // for email validation 
  isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  changeEmail = (event) => {

    if (!this.isValidEmail(event.target.value)) {
      this.setState({ error: 'Email is invalid!!' });
    } else {
      this.setState({ error: "" });
    }
    this.setState({ vendor_email: event.target.value });
  }

  aboutush(e) {
    this.setState({ aboutus: e.target.value });
  }

  deleteImage(e) {
    this.state.images.slice(e.currentTarget.id, 1);
    this.setState({ images: this.state.images });
  }


  changecity = (event) => {
    this.setState({ city: event.target.value });
  }

  changerestaurant_url = (event) => {
    this.setState({ restaurant_url: event.target.value });
  }

  changetitle(e) {
    this.setState({ title: e.target.value });
  }

  // timingf(e) {
  //   this.setState({ timings: e.target.value });
  // }

  changeOpeningTiming(e) {
    this.setState({ openingTiming: e.target.value });
  }

  changeClosingTiming(e) {
    this.setState({ closingTiming: e.target.value });
  }

  changephone_no = (e) => {
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ phone_no: e.target.value })
    }
  }


  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({ Latitude: latLng.lat, Longitude: latLng.lng, permanent_address: latLng, address: address });
      })
      .catch(error => console.error('Error', error));
  };


  handleChange = address => {
    this.setState({ address });
  };


  doCreatePost = async (e) => {
    if (this.state.name == "" || this.state.error.length !== 0 || this.state.commission == "" || this.state.address == "" ||this.state.banner == ""
     || this.state.phone_no == "" || this.state.description == "" || this.state.description == undefined || this.state.title == "" || this.state.aboutus == "") {
      alert("Please fill all fields");
    }
    else {
        try {
          let id = this.props.match.params.id;

          this.state.formData.append('vendor_email', this.state.vendor_email);
          this.state.formData.append('name', this.state.name);
          this.state.formData.append('vendor_url', this.state.restaurant_url);
          this.state.formData.append('openingTiming', this.state.openingTiming);
          this.state.formData.append('closingTiming', this.state.closingTiming);
          this.state.formData.append('address', this.state.address);
          this.state.formData.append('phone_no', this.state.phone_no);
          this.state.formData.append('description', this.state.description);
          this.state.formData.append('title', this.state.title);
          this.state.formData.append('commission', this.state.commission);
          this.state.formData.append('Latitude', this.state.Latitude);
          this.state.formData.append('Longitude', this.state.Longitude);
          this.state.formData.append('about_us', this.state.aboutus);

          // Update Api Call when all condition satisfied : -

          
          if (window.confirm("do you want to save your changes ?? ")) {
          const response = await Api.restaurantsUpdateById(id, this.state.formData);

          if (response.data && response.data.status == "success") {
            this.props.history.push("/app/restaurants");
          }
          else{
            alert(response.error.response.data.message);
          }
        }
      }
        catch (e) {
          alert(e.message)
        }
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState }, () => {
      var currentContent = editorState.getCurrentContent();
      var contentRaw = convertToRaw(currentContent);
      const value = currentContent.hasText() ? draftToHtml(contentRaw) : "";
      this.setState({ description: value });
    });
  };

  handleInput2 = e => {
    this.state.formData.append('banner_image', e.target.files[0]);
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      var streetaddress = reader.result.substr(0, reader.result.indexOf('/'));
      if (streetaddress == "data:image") {
        this.setState({banner: reader.result, formData: this.state.formData });
      }
    };
  }

  deleteImage2() {
    this.setState({ banner: "" });
  }

  commission = (e) => {
    this.setState({ commission: e.target.value });
  }

  render() {
    return (
      <div className="editdiv">
        <h1>Edit Restaurant</h1>
        <Row>
          <Col sm={12}>
            <div class="editdiv_in">
              {this.props.message && (
                <Alert className="alert-sm" bsstyle="info">
                  {this.props.message}
                </Alert>
              )}
              <FormGroup>
                <Label for="input-title">Name</Label>
                <Input
                  id="input-title"
                  type="text"
                  placeholder="Restaurant Name"
                  value={this.state.name}
                  required
                  onChange={this.changename.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="input-title">Title</Label>
                <Input
                  id="input-title"
                  type="text"
                  placeholder="Title"
                  value={this.state.title}
                  required
                  onChange={this.changetitle.bind(this)}
                />
              </FormGroup>

              <FormGroup>
                <Label for="input-datefrom">Email</Label>
                <Input
                  id="input-datefrom"
                  type="email"
                  placeholder="Email"
                  value={this.state.vendor_email}
                  onChange={this.changeEmail.bind(this)}
                />
              </FormGroup>

              {this.state.error && <span style={{ color: 'red' }}>{this.state.error}</span>}


              <FormGroup>
                <Label for="input-phone_no">Phone No</Label>
                <Input
                  id="input-phone_no"
                  type="text"
                  placeholder="Phone No"
                  maxlength="10"
                  minlength="10"
                  value={this.state.phone_no}
                  onChange={this.changephone_no.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="input-restaurant_url">Restaurant Url</Label>
                <Input
                  id="input-restaurant_url"
                  type="text"
                  placeholder="Restaurant Url"
                  value={this.state.restaurant_url == "undefined" ? "" : this.state.restaurant_url}
                  onChange={this.changerestaurant_url.bind(this)}
                />
              </FormGroup>

              <FormGroup>
                <Label for="input-timing">OpeningTiming</Label>
                <Input
                  id="input-timing"
                  type="text"
                  placeholder="OpeningTiming"
                  value={this.state.openingTiming}
                  onChange={this.changeOpeningTiming.bind(this)}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label for="input-timing">ClosingTiming</Label>
                <Input
                  id="input-timing"
                  type="text"
                  placeholder="ClosingTiming"
                  value={this.state.closingTiming}
                  onChange={this.changeClosingTiming.bind(this)}
                  disabled

                />
              </FormGroup>

              <FormGroup>
                <Label for="input-autocomplete">Address</Label>
                {this.state.gmapsLoaded && (<PlacesAutocomplete
                  value={this.state.address}
                  onChange={this.handleChange.bind(this)}
                  onSelect={this.handleSelect.bind(this)}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: 'Search Places ...',
                          className: 'location-search-input form-control',
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>


                  )}
                </PlacesAutocomplete>)}

              </FormGroup>
              <FormGroup>
                <Label for="input-opening_status">Description</Label>
                <Editor
                  editorState={this.state.editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={this.onEditorStateChange.bind(this)}
                />
              </FormGroup>


              <FormGroup>
                <Label for="input-phone_no">About us</Label>
                <Input
                  id="input-abpit"
                  type="text"
                  placeholder="About us"
                  value={this.state.aboutus}
                  onChange={this.aboutush.bind(this)}

                />
              </FormGroup>
              <FormGroup>
                <Label for="input-phone_no">Commission</Label>
                <Input
                  id="input-percentage"
                  type="text"
                  placeholder="Percentage"
                  value={this.state.commission}
                  onChange={this.commission.bind(this)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="featured_image">Banner Images</Label>
                <input type="file" className="form-control" onChange={this.handleInput2.bind(this)} />
              </FormGroup>
              <div class="row">
                <div className="form-group multi-preview">
                  {this.state.banner && this.state.banner !== "" ?
                    <div class="col-sm-4 col-md-3">
                      <div className="multiimages">
                        <i class="fa fa-times-circle" onClick={this.deleteImage2.bind(this)}></i>
                        <img src={this.state.banner} alt="Snow" defaultstyle="width:100%" className="w-100" />
                      </div>
                    </div> : ""}
                </div>
              </div>

              {/* <FormGroup sm={6} >
                <Label for="featured_image">Profile Images</Label>
                <input type="file" className="form-control" onChange={this.handleInput.bind(this)} />
              </FormGroup> */}

              {/* <FormGroup sm={6}>
                    <Button  color="success" type="button" >
                     Upload
                    </Button>
                  </FormGroup> */}

              <div class="row">
                <div className="form-group multi-preview">
                  {
                    console.log(" images show ", this.state.images)
                  }
                  {(this.state.images || []).map((url, i) => {
                    return (

                      <div class="col-sm-4 col-md-3">
                        <div className="multiimages">
                          <i class="fa fa-times-circle" id={i} onClick={this.deleteImage.bind(this)}></i>
                          <img src={url} alt="Snow" defaultstyle="width:100%" className="w-100" />
                        </div>
                      </div>

                    )
                  })}
                </div>
              </div>



              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button color="danger" type="button" onClick={this.doCreatePost.bind(this)} >
                    {this.props.loadings ? 'Updateing...' : 'Update Restaurant'}
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}



export default withRouter(RestaurantEdit);
