import React from 'react';
import { Switch, Route, withRouter } from 'react-router';

import productGoodWordsList from './list/productGoodWordsList';
import productGoodWordsEdit from './edit/productGoodWordsEdit';

class productGoodWords extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/app/productgoodwords" exact component={productGoodWordsList} />
        <Route path="/app/productgoodwords/edit/:id" exact component={productGoodWordsEdit} />
      </Switch>
    );
  }
}

export default withRouter(productGoodWords);
