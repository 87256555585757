import React from 'react';
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import PageTitle from "../../../components/PageTitle";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Api from '../../../components/RestApis/Api';

class GoodwordsList extends React.Component {
  constructor() {
    super();
    this.state = { dataSet: [] };
  }
  componentDidMount = async () => {
    try {
      const response = await Api.vendorGoodWordListing();
      console.log("Response : "+response)
      if (response.data && response.data.status === "success") {
        const dataSet = []
        response.data.vendorGoodWords.forEach(doc => {
          console.log(response.data.vendorGoodWords)
          dataSet.push([doc.vendor_name, doc.customer_name, <img src={ Api.image_path + doc.image[0]  }
          style={{ width: 200, height: 300, borderRadius: '10%'}} alt="Vendor Good Word" />, doc.rating, doc.description, doc._id, doc._id]);
        });
        this.setState({ dataSet: dataSet });
      }
      else {
        alert(response.error.response.data.message)
      }
    }
    catch (e) {
      alert(e.message);
    }

  }

  coupondelete = async (id) => {
    if (window.confirm("Delete the vendor good word?")) {
      try {
        const response = await Api.vendorGoodWordDeleteById(id);
        console.log("response : "+response)
        if (response.data && response.data.status === "success") {
          this.componentDidMount();
        }
        else {
          alert(response.error.response.data.message);
        }
      }
      catch (e) {
        alert(e.message);
      }
    }
  }

  getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTable: {
          root: {
            // backgroundColor: "#FF000"
          },
          paper: {
            boxShadow: "none"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            fontSize: '1.4rem'
          }
        },

        MuiTablePagination: {
          root: {
            fontSize: '1.5rem',
            fontWeight: '600'
          },

          caption: {
            fontSize: '1.5rem',
            fontWeight: 'Bold'
          },

          actions: {
            fontSize: '1.5rem'
          }

        },

        Muititle: {
          fontSize: '2rem'
        },

        MuiTypography: {
          root: {
            fontSize: '1.4rem',
            fontWeight: '700',
            lineHeight: '1.43',
            letterSpacing: '0.01071em'
          }
        },

        MUIDataTableHeadCell: {
          toolButton: {
            fontSize: '1.4rem',
            fontWeight: 'Bold'
          }
        },

        MuiSvgIcon: {
          root: {
            fontSize: '3.4rem'
          }
        },
        MuiInputBase:{
          input: {
            fontSize: '20px',
            border: '0',
            height: '6px',
            marginTop: '20px',
            display: 'block',
            padding: '12px',
            minWidth: '0',
            background: 'none',
            boxSizing: 'content-box',
            animationName: 'mui-auto-fill-cancel',
            letterSpacing: 'inherit',
            animationDuration: '10ms',
            webkitTapHighlightColor: 'transparent'
            }
          },
      },
    });


  render() {
    return (
      <>
        <PageTitle title="Vendor Good words" />
        {/* <div className="pull-right mt-n-xs">
          <Link to="/app/goodwords/new" className="btn btn-primary btn-inverse">
            Create New Vendor Good Words
          </Link>
        </div> */}
        <Grid container spacing={4}>
          <Grid item xs={12}>
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              title=""
              data={this.state.dataSet}
              columns={["Vendor Name", "Customer Name", "Image", "Rating", "Description",{
                label: "Edit",
                options: {
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <Link to={"/app/goodwords/edit/" + value} className='btn btn-primary'> Edit </Link>
                    )
                  }
                }
              }, 
              {
                  label: "Delete",
                  options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <button className='btn btn-danger' onClick={() => this.coupondelete(value)}>Delete</button>
                      )
                    }
                  }
                }]}
              options={{
                filterType: "checkbox",
                selectableRows: false
              }}
            />
            </MuiThemeProvider>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default withRouter(GoodwordsList);



