import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import Widget from '../../../components/Widget';
import { Multiselect } from 'multiselect-react-dropdown';
import { withRouter } from "react-router-dom";
import Api from '../../../components/RestApis/Api';
import { event } from 'jquery';
// import firebase from "firebase";
// const db = firebase.firestore();

class CouponEdit extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor(props) {
    super(props);

    this.state = {
      vendorName: '',
      code: '',
      datefrom: '',
      dateto: '',
      discount:'',
      maxUsers: ''
    };
  }

  dateConverter = (d) =>{
    let dF = new Date(d);

    let month = '' + (dF.getMonth() + 1);
    let day = '' + (dF.getDate() + 1);

    month.length < 2 ? month = '0' + month : month =  '' + month;
    
    day.length < 2 ? day = '0' + day : day = '' + day;

    let year =  dF.getFullYear();
    
    return [year,month, day].join('-');
  }

  componentDidMount=async()=>{
    let id = this.props.match.params.id;
    try{
      const response = await Api.showCouponEditTime(id);
      
      if(response.data && response.data.status === "success")
      {
        let data = response.data.showCoupon[0];

        this.setState({
          vendorName: data.vendorName ? data.vendorName : "",
          code: data.code ? data.code : "",
          datefrom: data.dateFrom ? this.dateConverter(data.dateFrom) : "",
          dateto: data.dateTo ? this.dateConverter(data.dateTo) : "",
          discount: data.discount ? data.discount : 0,
          maxUsers: data.maxUsers ? data.maxUsers : ''
          });
      }
    }catch(e){
      alert(e.message)
    }    
  }

  changecode = (event) => {
    this.setState({code: event.target.value});
  }
  changedatefrom = (event) => {
    this.setState({datefrom: event.target.value});
  }
  changedateto = (event) => {
    this.setState({dateto: event.target.value});
  }

  changeDiscount = (event) =>{
    this.setState({discount: event.target.value});
  }

  changeMaxUsers = (event)=>{
    this.setState({maxUsers: event.target.value});
  }



datecompare(){
  const g1 = new Date(this.state.datefrom);
  const g2 = new Date(this.state.dateto);
  if (g1.getTime() < g2.getTime())
  {
    return true;
  }
  else
  {
    return "Starting Date Must be Less than Enddate";
  }
}

  doCreatePost = async(e) => {
    e.preventDefault();
    let dateco=this.datecompare();
    if(dateco==true){
    try{
      let formData = new FormData();
      let id = this.props.match.params.id;
  
      formData.append("code",this.state.code);
      formData.append("datefrom",this.state.datefrom);
      formData.append("dateto",this.state.dateto);
      formData.append("discount",this.state.discount);
      formData.append("maxUsers",this.state.maxUsers);

      const response = await Api.couponUpdateByAdmin(id,formData);
      
        if(response.data && response.data.status === "success")
        {
          this.props.history.push("/app/coupons");
        }
        else
        {
          alert(response.error.response.data.message);
        }
    }
    catch(e)
    {
    alert(e.message)
    }
    }
  }

  render() {
    const {vendorName,code,datefrom,dateto,discount,maxUsers} = this.state;
    return (
      <div>
        <h1>Edit Coupon</h1>
        <Row>
          <Col sm={6}>
              <Form onSubmit={this.doCreatePost}>
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="info">
                    {this.props.message}
                  </Alert>
                )}
                <FormGroup>
                <Label for="input-vendorName">Vendor Name</Label>
                <Input
                  id="input-vendorName"
                  type="text"
                  placeholder="Vendor Name"
                  value={vendorName}
                  readOnly
                />
                </FormGroup>
                <FormGroup>
                  <Label for="input-code">Code</Label>
                  <Input
                    id="input-code"
                    type="text"
                    placeholder="Code"
                    value={code}
                    required
                    onChange={this.changecode}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-datefrom">Start Date</Label>
                  <Input
                    id="input-datefrom"
                    type="date"
                    placeholder="Date From"
                    value={datefrom}
                    onChange={this.changedatefrom}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-dateto">End Date</Label>
                  <Input
                    id="input-dateto"
                    type="date"
                    placeholder="Date To"
                    value={dateto}
                    onChange={this.changedateto}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-code">Discount</Label>
                  <Input
                    id="input-code"
                    type="number"
                    placeholder="%"
                    value={discount}
                    required
                    onChange={this.changeDiscount}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-code">Usage limit</Label>
                  <Input
                    id="input-code"
                    type="number"
                    placeholder="Enter 10 means 10 users can use it!"
                    value={maxUsers}
                    required
                    onChange={this.changeMaxUsers}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="danger" type="submit">
                      {this.props.isFetching ? 'Creating...' : 'Create'}
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
          </Col>
        </Row>
      </div>
    );
  }
}



export default withRouter(CouponEdit);
