import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';

import { withRouter } from "react-router-dom";
import Api from '../../../components/RestApis/Api';



class CategoryNew extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor(props) {
    super(props);

    this.state = {
      name : '',
    };
  }

  changeName = (event) => {
    this.setState({ name: event.target.value });
  }


  // Create post when conditions are satisfied

  doCreatePost = async (e) => {
    e.preventDefault();

    if (this.state.name == "") {
      alert("Fill the name field")
    }
    else {
      try {
        let formData = new FormData();

        formData.append("name", this.state.name);

        // let id = this.props.match.params.id;

        if(window.confirm("do you want to save your changes ?? ")) {
          const response = await Api.createNewCategory(formData);
          if (response.data && response.data.status == "Success") {
            this.props.history.push("/app/category");
          }
          else {
            alert(response.data.message);
          }
        }

        } 


      catch (e) {
        alert(e.message);
      }
    }
  }

  render() {
    return (
      <div>
        <h1>Create New Category</h1>
         <div class="editdiv_in">
        <Row>
          <Col sm={6}>
              <Form onSubmit={this.doCreatePost}>
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="info">
                    {this.props.message}
                  </Alert>
                )}

                <FormGroup>
                  <Label for="input-title">Name</Label>
                  <Input
                    id="input-title"
                    type="text"
                    placeholder="Title"
                    value={this.state.name}
                    required
                    onChange={this.changeName}
                  />
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="danger" type="submit">
                      {this.props.isFetching ? 'Creating...' : 'Create'}
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
          </Col>
        </Row>
        </div>
      </div>
    );
  }
}



export default withRouter(CategoryNew);
