import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { withRouter } from "react-router-dom";
import firebase from "firebase";
import Widget from '../../../components/Widget';
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from 'axios';
import Api from '../../../components/RestApis/Api';
// const db = firebase.firestore();
const apiurl = Api.apiurl; //"http://192.168.1.70:5000/";
class GoodwordsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      name : "",
      description : "",
      imagess : "",
      formData: new FormData(),
      loadings: false,
      loaded: false
    };
  }
  componentDidMount = async () => {
    var id = this.props.match.params.id;
    try {
      const response = await Api.vendorGoodwordsById(id);
      console.log("Hello : "+response);
      if (response && response.data.status === "success") {
        console.log("response" +response)
        let data = response.data.vendorReview;
        this.setState({
          name: data.name ? data.name : "",
          description: data.description ? data.description : "",
          imagess: Api.image_path + data.featureImage[0] ? Api.image_path + data.featureImage[0] : "",
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(data.description)
            )),
          loadings: false,
          loaded: false
        });
      }
    }
    catch (e) { }
  }
  onEditorStateChange = (editorState) => {
    this.setState({ editorState }, () => {
      var currentContent = editorState.getCurrentContent();
      var contentRaw = convertToRaw(currentContent);
      const value = currentContent.hasText() ? draftToHtml(contentRaw) : "";
      this.setState({ description: value });
    });
  };



  submitform = async () => {
      try {
        this.state.formData.append('name', this.state.name);
        this.state.formData.append('description', this.state.description);
        let id = this.props.match.params.id;
        const response = await Api.updateVendorReview(id, this.state.formData);
        console.log('response : ', response)
        if (response.data && response.data.status === "success") {
          this.props.history.push("/app/goodwords");
        }
        else {
          alert(response.error.response.data.message);
        }
      }
      catch (e) {
        alert(e.message)
      }
  }

  handleInput = e => {
    this.state.formData.append('featureImage', e.target.files[0]);
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      var streetaddress = reader.result.substr(0, reader.result.indexOf('/'));
      if (streetaddress == "data:image") {
        //  this.handleUpload(reader.result)
        this.setState({ imagess: reader.result, loaded: true });
      }
    };
  }
  deleteImage() {
    this.setState({ imagess: "" });
  }
  changetitle = (event) => {
    this.setState({ name: event.target.value });
  }

  render() {
    const { code, title, datefrom, dateto } = this.state;
    return (
      <div>
        <h1>Edit Vendor Good words</h1>
        <Row>
          <Col sm={12}>
            <Widget

            >
              <div>
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="info">
                    {this.props.message}
                  </Alert>
                )}

                <FormGroup>
                  <Label for="input-title">Name</Label>
                  <Input
                    id="input-title"
                    type="text"
                    placeholder="Name"
                    value={this.state.name}
                    required
                    onChange={this.changetitle.bind(this)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="input-opening_status">Description</Label>
                  <Editor
                    editorState={this.state.editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange.bind(this)}
                  />
                </FormGroup>

                <FormGroup >
                  <Label for="featured_image">Images</Label>
                  <input type="file" className="form-control" onChange={this.handleInput.bind(this)} />
                </FormGroup>
                <div class="row">
                  <div className="form-group multi-preview">
                    {this.state.imagess !== "" ? <div class="col-sm-4 col-md-3">
                      <div className="multiimages">
                        <i class="fa fa-times-circle" onClick={this.deleteImage.bind(this)}></i>
                        <img src={this.state.imagess} alt="Snow" defaultstyle="width:100%" className="w-100" />
                      </div>
                    </div> : ""}
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="danger" type="button" onClick={this.submitform.bind(this)}>
                      {this.state.loadings == true ? 'Updateing...' : 'Update'}
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(GoodwordsEdit);
