import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
} from 'reactstrap';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';
import Api from '../../../components/RestApis/Api'

import { withRouter } from "react-router-dom";
// import firebase from "firebase";
// const db = firebase.firestore();

class CustomerNew extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new Customer',
  };

  constructor(props) {
    super(props);

    this.state = {
      userName : '',
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      password : '',
    };
  }



  changeUserName = (event) => {
    this.setState({ userName : event.target.value })
  }

  changefname = (event) => {
    this.setState({firstName: event.target.value});
  }
  changelname = (event) => {
    this.setState({lastName: event.target.value});
  }
  changeemail = (event) => {
    this.setState({email: event.target.value});
  }
  changephone = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)){
      this.setState({mobile: event.target.value});
    }
  }
  changePassword  =(event) => {
    this.setState({ password : event.target.value });
  }

  changeClear = () =>{
    this.setState({userName : ""})
    this.setState({firstName : ""})
    this.setState({lastName : ""})
    this.setState({email : ""})
    this.setState({mobile : ""})
    this.setState({password : ""})
  }

  doCreatePost = async(e) => {
    e.preventDefault();

    if(this.state.userName === "") {
      alert("Please, fill the username!!!")
    }else if(this.state.firstName === ""){
      alert("Please, fill the first name!!!")
    }else if(this.state.lastName === ""){
      alert("Please, fill the last name!!!")
    }else if(this.state.email === ""){
      alert("Please, fill the email!!!")
    }else if(this.state.mobile === ""){
      alert("Please, fill the mobile number!!!")
    }else if(this.state.password === ""){
      alert("Please, fill the password!!!")
    }
    else {
          try{
              let formData = new FormData();
        
              formData.append("userName", this.state.userName);
              formData.append("firstName", this.state.firstName);
              formData.append("lastName", this.state.lastName);
              formData.append("email", this.state.email);
              formData.append("mobile", this.state.mobile);
              formData.append("password", this.state.password);

              const response = await Api.createCustomerByAdmin(formData);

              if(response) {
                alert(response.data.message)
                this.props.history.push("/app/customers");
              }
              else {
                  alert(response.error.response.data.message);
              }
          }
          catch (e) {
            alert(e.message);
          }
    }
  }

  render() {
    const {userName,email,firstName,lastName,mobile,password} = this.state;
    return (
      <div>
        <h1>Create new Customer</h1>
        <Row>
          <Col sm={6}>
            <Widget
              title={
                <span>
                  Add Customer
                </span>
              }
            >
              <Form onSubmit={this.doCreatePost}>
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="info">
                    {this.props.message}
                  </Alert>
                )}
                <FormGroup>
                  <Label for="input-fname">User Name</Label>
                  <Input
                    id="input-userName"
                    type="text"
                    placeholder="User Name"
                    value={userName}
                    required
                    onChange={this.changeUserName}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="input-fname">First Name</Label>
                  <Input
                    id="input-fname"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    required
                    onChange={this.changefname}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="input-name">Last Name</Label>
                  <Input
                    id="input-name"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    required
                    onChange={this.changelname}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-email">Email</Label>
                  <Input
                    id="input-email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.changeemail}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-phone">Mobile</Label>
                  <Input
                    id="input-phone"
                    placeholder="Mobile"
                    maxLength={10}
                    minLength={10}
                    value={mobile}
                    onChange={this.changephone}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="input-fname">Password</Label>
                  <Input
                    id="input-password"
                    type="password"
                    placeholder="password"
                    value={password}
                    required
                    onChange={this.changePassword}
                  />
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button type="submit" style={{color : 'white'}}>
                      {this.props.isFetching ? 'Creating...' : 'Create'}
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup>
                  <Button type="reset" style={{marginLeft : '230px', color : 'white'}} onClick={this.changeClear}>
                    Clear
                  </Button>
                  </ButtonGroup>
                </div>
              </Form>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}



export default withRouter(CustomerNew);
