import React from 'react';
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';

// components
import PageTitle from "../../../components/PageTitle";

// data
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import Api from '../../../components/RestApis/Api';


class StoreList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor() {
    super();
    this.state = { dataSet: [], errormsg: "" };
  }




  componentDidMount = async () => {

    try {
      let response = await Api.storeList();
      if (response && response.data.status === "Success") {
        console.log('Data responce : ',response)
        const filter = [];
        response.data.Categories.forEach(doc => {
          console.log("Image Path : ",Api.image_path + doc.image)
          filter.push([doc.name, <img src={ Api.image_path + doc.image } style={{ width: 200, height: 200, borderRadius: '10%'}}  alt="storeCategoryImage"/>,
                       doc._id, doc._id]);
        });
        this.setState({ dataSet: filter });
      }
    }
    catch (e) {
      this.setState({ errormsg: e.message });
    }
  }

  menudelete = async (id) => {
    if (window.confirm("Delete the item?")) {
      try {
        const response = await Api.storeDeleteById(id);
        if (response && response.data.status === "success") {
          alert(response.data.message)
          this.componentDidMount();
        }
        else {
          alert(response.error.response.data.message);
        }
      }
      catch (e) {
        alert(e.message);
      }
    }
  }



  getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTable: {
          root: {
            // backgroundColor: "#FF000"
          },
          paper: {
            boxShadow: "none"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            fontSize: '1.4rem'
          }
        },

        MuiTablePagination: {
          root: {
            fontSize: '1.5rem',
            fontWeight: '600'
          },

          caption: {
            fontSize: '1.5rem',
            fontWeight: 'Bold'
          },

          actions: {
            fontSize: '1.5rem'
          }

        },

        Muititle: {
          fontSize: '2rem'
        },

        MuiTypography: {
          root: {
            fontSize: '1.4rem',
            fontWeight: '700',
            lineHeight: '1.43',
            letterSpacing: '0.01071em'
          }
        },

        MUIDataTableHeadCell: {
          toolButton: {
            fontSize: '1.4rem',
            fontWeight: 'Bold'
          }
        },

        MuiSvgIcon: {
          root: {
            fontSize: '3.4rem'
          }
        },

        MuiInputBase:{
          input: {
            fontSize: '20px',
            border: '0',
            height: '6px',
            marginTop: '20px',
            display: 'block',
            padding: '12px',
            minWidth: '0',
            background: 'none',
            boxSizing: 'content-box',
            animationName: 'mui-auto-fill-cancel',
            letterSpacing: 'inherit',
            animationDuration: '10ms',
            webkitTapHighlightColor: 'transparent'
            }
          },


      },
    });

    

  render() {
    return (
      <>

        <div class="row">
          <div class="col-md-8"><PageTitle title="Store Category" /></div>
          <div class="col-md-4"> <Link to="/app/store/new" className="btn btn-primary btn-inverse pull-right ">
            Create New Store Category
          </Link></div>
        </div>
        
        <Grid container spacing={4}>
          <Grid item xs={12}>

            <MuiThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable
                title=""
                data={this.state.dataSet}
                columns={["Name", "Image", {
                  label: "Edit",
                  options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <Link to={"/app/store/edit/" + value} className='btn btn-primary'> Edit </Link>
                      )
                    }
                  }
                },
                  {
                    label: "Delete",
                    options: {
                      customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                          <button className='btn btn-danger' onClick={() => this.menudelete(value)}>Delete</button>
                        )
                      }
                    }
                  }
                ]}
                options={{
                  filterType: "checkbox",
                  selectableRows: false
                }}
              />
            </MuiThemeProvider>

          </Grid>
        </Grid>
      </>
    );
  }
}
export default withRouter(StoreList);



