import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';
import Api from '../../../components/RestApis/Api';

import { withRouter } from "react-router-dom";
// import firebase from "firebase";
// const db = firebase.firestore();

class CustomerEdit extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new Customer',
    description: 'About description',
  };

  constructor(props) {
    super(props);

    this.state = {
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
    };
  }
  componentDidMount = async() =>{
    let id = this.props.match.params.id;
    this.setState({ dataid: id });
    try{
      const response = await Api.showCustomerFields(id);
      if (response && response.data.status === "success"){
        let data = response.data.customerDetails;

        this.setState({
          userName : data.userName ? data.userName : "",
          firstName : data.firstName ? data.firstName : "",
          lastName : data.lastName ? data.lastName : "",
          email : data.email ? data.email : "",
          mobile: data.mobile ? data.mobile : ""
        })
      }
    }catch(e){
      this.setState({ errormsg: e.message });
    }
  }


changefname = (event) => {
    this.setState({firstName: event.target.value});
  }
  changelname = (event) => {
    this.setState({lastName: event.target.value});
  }

  changeemail = (event) => {
    this.setState({email: event.target.value});
  }
  changephone = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)){
      this.setState({mobile: event.target.value});
    }
  }

  doCreatePost = async(e) => {
    
    e.preventDefault();

      if(this.state.userName == "") {
        alert("Fill the userName field")
      }
      else {
            try{
                let formData = new FormData();
                let id = this.props.match.params.id;
                
                formData.append("userName", this.state.userName);
                formData.append("firstName", this.state.firstName);
                formData.append("lastName", this.state.lastName);
                formData.append("email", this.state.email);
                formData.append("mobile", this.state.mobile);

                const response = await Api.updateCustomerByAdmin(id, formData);

                if(response) {
                  this.props.history.push("/app/customers");
                }
                else {
                    alert(response.error.response.data.message);
                }
            }
            catch (e) {
              alert(e.message);
            }
      }

  }

  render() {
    const {userName,email,firstName,lastName,mobile} = this.state;
    return (
      <div>
        <h1>Edit Customer</h1>
        <Row>
          <Col sm={6}>
            <Widget
              title={
                <span>
                  Add Customer
                </span>
              }
            >
              <Form onSubmit={this.doCreatePost}>
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="info">
                    {this.props.message}
                  </Alert>
                )}

                <FormGroup>
                  <Label for="input-fname">Username</Label>
                  <Input
                    id="input-username"
                    type="text"
                    placeholder="Username"
                    value={userName}
                    readOnly
                    // onChange={this.changeUserName}
                  />
                </FormGroup>
    
                <FormGroup>
                  <Label for="input-fname">First Name</Label>
                  <Input
                    id="input-fname"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    required
                    onChange={this.changefname}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="input-lname">Last Name</Label>
                  <Input
                    id="input-lname"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    required
                    onChange={this.changelname}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-email">Email</Label>
                  <Input
                    id="input-email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.changeemail}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="input-phone">Mobile</Label>
                  <Input
                    id="input-phone"
                    placeholder="Mobile"
                    maxLength={10}
                    minLength={10}
                    value={mobile}
                    onChange={this.changephone}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="danger" type="submit">
                      {this.props.isFetching ? 'Saving...' : 'Save'}
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}



export default withRouter(CustomerEdit);
