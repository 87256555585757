import React from 'react';
import { Grid } from "@material-ui/core";
// import { makeStyles} from "@material-ui/styles";
// import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// components
import PageTitle from "../../../components/PageTitle";
// import Widget from "../../../components/Widget";
// import Table from "../../dashboard/components/Table/Table";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import Api from '../../../components/RestApis/Api';

class MenusList extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor() {
    super();
    this.state = {
      dataSet: [], columns: [
        { title: 'Dish Name', field: 'name'},
        { title: 'Image', field: 'images' , align:'center'},
        { title: 'Foodtype', field: 'foodType',align:'center' },
        { title: 'ProductType', field: 'productType',align:'center' },
        { title: 'Required Member', field: 'memberReq', emptyValue:()=><em>null</em> ,align:'center'},
        { title: 'Quantity Available', field: 'stocks', align:'center' },
        { title: 'Description', field: 'description', align:'center'},
        { title: 'Price', field: 'price', align:'center' ,defaultSort :'asc'}
      ]
    }

  }


  componentDidMount = async () => {

    try {
      let id = this.props.match.params.id;
      let response = await Api.vendorProductById(id);
      console.log(Api.image_path);
      if (response && response.data.status === "success") {
        const filter = [];
       
        response.data.productListing.forEach(doc => {
          filter.push({
            name: doc.name, images: <img src={ Api.image_path + doc.images[0]}
            style={{ width: 200, height: 300, borderRadius: '10%' }} />, foodType: doc.foodType, productType: doc.productType == 1 ? 'Single' : 'Group',
            memberReq: doc.memberReq, stocks: doc.stocks, description: doc.description, price: doc.price
          });
        });
        this.setState({ dataSet: filter });
      }
    }
    catch (e) {
      this.setState({ errormsg: e.message });
    }
  }

  
  render() {
    return (
      <>
        <PageTitle title="Menus" />
        <Link to={'/app/restaurants'}><i class="fa fa-arrow-left romoveback"></i></Link>
        <div className="pull-right mt-n-xs">

        </div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
 
            <MaterialTable
              title="Menus"
              options={{sorting : true, headerStyle :{fontSize : '30px',fontWeight: 'bold'}}}
              data={this.state.dataSet}
              columns={this.state.columns}
              
            />
          
          </Grid>
        </Grid>
      </>
    );
  }
}



export default withRouter(MenusList);


