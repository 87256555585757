import React from 'react';
import { Grid } from "@material-ui/core";
// import { makeStyles} from "@material-ui/styles";
// import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// components
import PageTitle from "../../../components/PageTitle";
// import Widget from "../../../components/Widget";
// import Table from "../../dashboard/components/Table/Table";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import Api from '../../../components/RestApis/Api';

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

class SeeDishes extends React.Component {
    constructor() {
        super();
        this.state = {
            dataSet: [],
            columns: [
                { title: 'Dish Name', field: 'dish_name' },
                { title: 'Image', field: 'image', align: 'center' },
                { title: 'Actual Price', field: 'price' },
                { title: 'Quantity', field: 'quantity' },
            ]
        }
    }

    


    componentDidMount = async () => {
        try {
            let id = this.props.match.params.id;
            let response = await Api.adminSeeDishes(id);
            if (response && response.data.status === "Success") {
                const filter = [];
                response.data.seeDishes.forEach(item => {
                    filter.push({
                        dish_name: item.dish_name, image: <img src={Api.image_path + item.image[0]}
                            style={{ width: 200, height: 300, borderRadius: '10%' }} />, price: item.price, quantity: item.quantity
                    });
                });
                this.setState({ dataSet: filter });
            }
        }
        catch (e) {
            alert(e.message);
        }
    }

    render() {
        return (
            <>
                
                    <PageTitle title="See Dishes" />
                    <Link to={'/app/orders'}><i class="fa fa-arrow-left romoveback"></i></Link>



                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                        
                            <MaterialTable
                                title="See Dishes"
                                options={{ sorting: true, headerStyle: { fontSize: '15px', fontWeight: 'bold' } }}
                                data={this.state.dataSet}
                                columns={this.state.columns}
                                style={{ headerStyle : { backgroundColor : 'red' } }}
                            />
                      
                        </Grid>
                    </Grid>
                
            </>
        );
    }
}



export default withRouter(SeeDishes);


