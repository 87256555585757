import React from 'react';
import axios from 'axios';
import { resolve } from './resolve.js';


 const apiurl = "https://group-buy.thermalcode.com/appapi";       // Server Api link

// const apiurl = "http://192.168.1.134:8005/appapi";                 // Local api link


class Api extends React.Component {

  static apiurl;
  static image_path = "http://192.168.1.134:8005/my_uploaded_files/images/";        // static image path 



  // Login page api 

  static async adminlogin(email, password) {
    let body = { email: email, password: password };
    return resolve(axios.post(apiurl + `/admin/login`, body).then(res => res.data));
  }

  // Product Category 

  // Product category Api 
  // Add New Product category
  static async createNewCategory(formData) {
    return await resolve(axios.post(apiurl + '/add_product_category/', formData).then(res => res.data));
  }

  // List 
  static async productCategoriesList() {
    return await resolve(axios.get(apiurl + `/product_category_listing`).then(res => res.data));
  }

  // Update product categories Api 
  static async categoriesUpdateById(id, formData) {
    return await resolve(axios.put(apiurl + `/update_product_category/` + id, formData).then(res => res.data));
  }

  // Show product categories by id 
  static async categoriesById(id) {
    return await resolve(axios.get(apiurl + `/show_product_category/` + id).then(res => res.data));
  }

  // Delete categories by id 

  static async categoriesDeleteById(id) {
    return await resolve(axios.delete(apiurl + `/delete_product_category/` + id).then(res => res.data));
  }

  

  static async notificationAdd(formData) {
    return await resolve(axios.post(apiurl + `/notifications/add`, formData).then(res => res.data));
  }


  static async sendnotification(body) {
    return await resolve(axios.post(apiurl + `/notifications/send`, body).then(res => res.data));
  }

  // Order List Api 
  static async ordersList() {
    return await resolve(axios.get(apiurl + `/admin_side_orders`).then(res => res.data));
  }

  

  // Vendor list api 
  static async restaurantsList() {
    return await resolve(axios.get(apiurl + `/admin_side_vendors`).then(res => res.data));
  }

  // Add new restaurant 
  static async restaurantsAdd(formData) {
    return await resolve(axios.post(apiurl + `/vendor_register`, formData).then(res => res.data));
  }

  // Vendor Update api 
  static async restaurantsUpdateById(id, formData) {
    return await resolve(axios.put(apiurl + `/admin_edit_vendor/` + id, formData).then(res => res.data));
  }

  static async notificationList(id) {
    return await resolve(axios.get(apiurl + `/notifications/get/` + id).then(res => res.data));
  }

  static async restaurantsById(id) {
    return await resolve(axios.get(apiurl + `/restaurants/edit/` + id).then(res => res.data));
  }

  static async restaurantsCount() {
    return await resolve(axios.get(apiurl + `/restaurants/count`).then(res => res.data));
  }

  static async adminSeeDishes(id) {
    return await resolve(axios.get(apiurl + '/admin_see_dishes/' + id ).then(res => res.data));
  }

  static async vendorProductById(id) {
    return await resolve(axios.get(apiurl + `/admin_menu_list/` + id).then(res => res.data));
  }

  static async restaurantsDeleteById(id) {
    return await resolve(axios.delete(apiurl + `/admin_delete_vendor/` + id).then(res => res.data));
  }

  //vendorCoupons

  static async VendorCouponsList() {
    return await resolve(axios.get(apiurl + `/admin_side_coupons_list`).then(res => res.data));
  }

  static async addCouponByAdmin(formData) {
    return await resolve(axios.post(apiurl + `/add_coupon_by_admin`, formData).then(res => res.data));
  }

  static async showCouponEditTime(id) {
    return await resolve(axios.get(apiurl + `/show_coupon_edit_time/` + id).then(res => res.data));
  }

  static async couponUpdateByAdmin(id, formData) {
    return await resolve(axios.put(apiurl + `/update_coupon_by_admin/` + id, formData).then(res => res.data));
  }

  static async couponsDeleteById(id) {
    return await resolve(axios.delete(apiurl + `/delete_coupon_by_admin/` + id).then(res => res.data));
  }


  //Page

  static async pageById(id) {
    return await resolve(axios.get(apiurl + `/pages/edit/` + id).then(res => res.data));
  }

  static async pageUpdateById(id, formData) {
    return await resolve(axios.post(apiurl + `/pages/update/` + id, formData).then(res => res.data));
  }

  //Vendor Good Words

  // static async goodwordsAdd(body) {
  //   return await resolve(axios.post(apiurl + `/goodwords/add`, body).then(res => res.data));
  // }

  static async vendorGoodWordListing() {
    return await resolve(axios.get(apiurl + `/vendor_goodWords_listing`).then(res => res.data));
  }

  static async vendorGoodwordsById(id) {
    return await resolve(axios.get(apiurl + `/show_vendor_review_edit_time/` + id).then(res => res.data));
  }

  static async updateVendorReview(id, formData) {
    return await resolve(axios.put(apiurl + `/update_vendor_review/` + id, formData).then(res => res.data));
  }

  static async vendorGoodWordDeleteById(id) {
    return await resolve(axios.delete(apiurl + `/vendor_good_word_delete_by_admin/` + id).then(res => res.data));
  }

  //Product Good Words
  static async productGoodWordListing() {
    return await resolve(axios.get(apiurl + `/product_good_words_listing`).then(res => res.data));
  }

  static async productGoodWordDeleteById(id) {
    return await resolve(axios.delete(apiurl + `/product_good_word_delete_by_admin/` + id).then(res => res.data));
  }

  static async productGoodwordsById(id) {
    return await resolve(axios.get(apiurl + `/show_product_review_edit_time/` + id).then(res => res.data));
  }

  static async updateProductReview(id, formData) {
    return await resolve(axios.put(apiurl + `/update_product_review/` + id, formData).then(res => res.data));
  }



 //Customer

  static async createCustomerByAdmin(formData){
    return await resolve(axios.post(apiurl + `/register`, formData).then(res => res.data));
  }

  static async customerList() {
    return await resolve(axios.get(apiurl + `/admin_side_customers`).then(res => res.data));
  }

  static async showCustomerFields(id){
    return await resolve(axios.get(apiurl + `/show_customer_details/` + id).then(res => res.data));
  }

  static async updateCustomerByAdmin(id,formData){
    return await resolve(axios.put(apiurl + `/update_customer_by_admin/` + id, formData).then(res => res.data));
  }


  static async deleteCustomerById(id) {
    return await resolve(axios.delete(apiurl + `/admin_delete_customer/` + id).then(res => res.data));
  }


  // Store 

  static async storeAdd(formData) {
    return await resolve(axios.post(apiurl + `/add_category`, formData).then(res => res.data));
  }

  static async storeList() {
    return await resolve(axios.get(apiurl + `/category_listing`).then(res => res.data));
  }

  static async storeById(id) {
    return await resolve(axios.get(apiurl + `/show_store_category_fields/` + id).then(res => res.data));
  }

  static async storeUpdateById(id, formData) {
    return await resolve(axios.post(apiurl + `/updateCategories/` + id, formData).then(res => res.data));
  }

  static async storeDeleteById(id) {
    return await resolve(axios.delete(apiurl + `/delete_category_by_admin/` + id).then(res => res.data));
  }
}

export default Api;



