import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Alert,
  Label,
} from 'reactstrap';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';


import Widget from '../../../components/Widget';
// import { Editor } from "react-draft-wysiwyg";

// import {
//   EditorState,
//   convertToRaw,
//   convertFromHTML,
//   ContentState
// } from "draft-js";

// import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { withRouter } from "react-router-dom";
import Geocode from "react-geocode";
import Api from '../../../components/RestApis/Api';
import validator from 'validator';
// const db = firebase.firestore();

// const options = [
//   { value: 'Own', label: 'Own' },
//   { value: 'Goodforlow', label: 'Goodforlow' }
// ];

// const google = window.google

class RestaurantNew extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  static defaultProps = {
    isFetching: false,
    message: null,
  };

  static meta = {
    title: 'Create new post',
    description: 'About description',
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      vendor_email: '',
      emailError: '',
      restaurant_url: '',
      status: 2,
      address: "",
      phone_no: "",
      description: "",
      password: "",
      title: "",
      city: "",
      aboutus: "",
      loading: false,
      loadings: false,
      timing: "",
      Latitude: 0,
      Longitude: 0,
      images: [], fimage: "", banner: {}, fileObj: [],
      fileArray: [],
      files: [],
      gmapsLoaded: false,
      imagess: [],
      commission: "",
      selectedOption: null,
      optionstype: [],
      store_id: "",
    };

  }


  componentDidMount = async () => {
    setTimeout(() => {
      this.initMap();
    }, 2000);
    let response = await Api.storeList();
    if (response && response.data.status === "success") {
      let newval = [];
      for (var i = 0; i < response.data.users.length; i++) {
        newval.push({ value: response.data.users[i]._id, label: response.data.users[i].name });
      }
      this.setState({ optionstype: newval });
    }
  }


  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    })
  }

  handleChangeDrop = (selectedOption) => {
    this.setState({ selectedOption: selectedOption, store_id: selectedOption.value })
  };

  changename = (event) => {
    this.setState({ name: event.target.value });
  }


  changeVendor_email = (event) => {
    if (!validator.isEmail(event.target.value)) {
      this.setState({ emailError: 'Enter valid Email!' });
    }
    else {
      this.setState({ emailError: '' });
    }
    this.setState({ vendor_email: event.target.value });
  }


  // changerestaurant_url = (event) => {
  //   this.setState({ restaurant_url: event.target.value });
  // }

  changephone_no = (e) => {

    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ phone_no: e.target.value })
    }
  }


  // handle select for address   
  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({ Latitude: latLng.lat, Longitude: latLng.lng, permanent_address: latLng, address: address });
      })
      .catch(error => console.error('Error', error));
  };


  handleChange = address => {
    this.setState({ address, location: [this.state.Latitude, this.state.Longitude] });
  };


  // sendemail(a, b) {
  //   var body = { name: a, email: b };
  //   axios.post('https://digittrix-staging.live/webci/foodapi/send_email', body, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   }).then((res) => {
  //     this.setState({ loadings: false });
  //     this.props.history.push("/app/restaurants");
  //   })
  // }


  password(e) {
    this.setState({ password: e.target.value });
  }


  submitform = async (e) => {
    if (this.state.name === "" || this.state.emailError.length !== 0 || this.state.address === "" || this.state.phone_no === "" || this.state.vendor_email === "" || this.state.password === "") {
      alert("please fill all the fields");
    }
    else {
      try {

        let formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('address', this.state.address);
        formData.append('phone_no', this.state.phone_no);
        formData.append('vendor_email', this.state.vendor_email);
        formData.append('password', this.state.password);
        formData.append('status', this.state.status);
        formData.append('Latitude', this.state.Latitude);
        formData.append('Longitude', this.state.Longitude);

        if (window.confirm("do you want to save your changes ?? ")) {
          const response = await Api.restaurantsAdd(formData);

          if (response.data && response.data.status == "success") {
            this.props.history.push("/app/restaurants");
          }
          else {
            alert(response.error.response.data.message);
          }
        }
      }
      catch (e) {
        alert(e.message);
      }
    }
  }

  // function for clear button 
  handleClear = () => {
    this.setState({ name: "" });
    this.setState({ address: "" });
    this.setState({ phone_no: "" });
    this.setState({ vendor_email: "" });
    this.setState({ password: "" });
    this.setState({ Latitude: "" });
    this.setState({ Longitude: "" });
  }


  render() {
    return (
      <div>
        <h1>Create New Restaurant</h1>
        <Row>
          <Col sm={12}>
            <Widget
              title={
                <span>
                  Add Restaurant
                </span>
              }
            >
              <div>
                {this.props.message && (
                  <Alert className="alert-sm" bsstyle="info">
                    {this.props.message}
                  </Alert>
                )}

                <FormGroup>
                  <Label for="input-title">Name</Label>
                  <Input
                    id="input-title"
                    type="text"
                    placeholder="Restaurant Name"
                    value={this.state.name}
                    required
                    onChange={this.changename.bind(this)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="input-datefrom">Email</Label>
                  <Input
                    id="input-datefrom"
                    type="email"
                    placeholder="Email"
                    value={this.state.vendor_email}
                    onChange={this.changeVendor_email.bind(this)}
                  />
                  {this.state.emailError !== "" ? <span style={{
                    fontWeight: 'bold',
                    color: 'red',
                  }}>{this.state.emailError}</span> : ""}
                </FormGroup>


                <FormGroup>
                  <Label for="input-phone_no">Phone No</Label>
                  <Input
                    id="input-phone_no"
                    maxLength={10}
                    minLength={10}
                    placeholder="Phone No"
                    value={this.state.phone_no}
                    onChange={this.changephone_no.bind(this)}
                  />
                </FormGroup>


                <FormGroup>
                  <Label for="input-autocomplete">Address</Label>
                  {this.state.gmapsLoaded && (
                    <PlacesAutocomplete
                      value={this.state.address}
                      onChange={this.handleChange.bind(this)}
                      onSelect={this.handleSelect.bind(this)}
                      id='delta'
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: 'Search Places ...',
                              className: 'location-search-input form-control',
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>)}
                </FormGroup>
                <FormGroup>
                  <Label for="input-phone_no">Password</Label>
                  <Input
                    id="input-password"
                    type="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.password.bind(this)}
                  />
                </FormGroup>
                {/* Add restaurant button  */}
                <div className="d-flex justify-content-end">
                  <ButtonGroup>
                    <Button color="danger" type="button" onClick={this.submitform.bind(this)} style={{ borderRadius: "20px" }}>
                      {this.state.loadings == true ? 'Creating...' : 'Add Restaurant'}
                    </Button>
                    <Button color="danger" onClick={this.handleClear.bind(this)} style={{ marginLeft: '1rem', borderRadius: "20px" }}>
                      Clear
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(RestaurantNew);

